import { FBQ_PIXEL_ID } from "../constants";

export const FB_PIXEL_ID = FBQ_PIXEL_ID;

export const pageview = () => {
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  window.fbq("track", name, options);
};


export const sendWebinarEvent = (eventname, pagedata) => {
  event(eventname, {
    content_name: pagedata.typingText,
    content_type: "event",
    content_ids: [`EVENT-${pagedata.id}`],
    category_name: pagedata.heroData.eventDetails.influencerName,
    value: pagedata.heroData.eventDetails.salePrice,
    subtotal: pagedata.heroData.eventDetails.salePrice,
    page_title: "Event - " + pagedata.typingText,
    contents: [
      {
        id: `EVENT-${pagedata.id}`,
        quantity: 1,
        price: pagedata.heroData.eventDetails.salePrice,
      },
    ],
    currency: "INR",
  });
}