import React, { memo } from "react";
import Head from "next/head";
import GoTop from "./GoTop";
import { BASE_URL } from "../../constants";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Head>
        <title>ToppersNotes - Unleash the topper in you</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta property="og:url" content={BASE_URL} />
        <meta property="og:type" content="website" />

        <meta
          property="og:title"
          content="ToppersNotes - Unleash the topper in you"
        />

        <meta
          property="og:description"
          content="Save your most valuable Time, and Money with expert-curated
                content and personalized learning."
        />
        <meta property="og:image" content={BASE_URL + "/og-new-favicon.png"} />

        <script type="text/javascript" src="/scripts/gtag.js"></script>

      </Head>

      {children}

      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </React.Fragment>
  );
};

export default memo(Layout);
