import {
  AW_TRACKING_ID_NEXTJS,
  GA_TRACKING_ID_NEXTJS,
  GTM_TRACKING_ID_NEXTJS,
} from "../constants";

export const AW_TRACKING_ID = AW_TRACKING_ID_NEXTJS;
export const GA_TRACKING_ID = GA_TRACKING_ID_NEXTJS;
export const GTM_TRACKING_ID = GTM_TRACKING_ID_NEXTJS;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
export const conversion = (action, value, currency, transaction_id) => {
  window.gtag("event", action, {
    // send_to: AW_TRACKING_ID + "/2DOmCOvkwV4Qgu6ZzQM",
    send_to: AW_TRACKING_ID + '/l5yNCPuF27kZEILumc0D',
    value: value,
    currency: currency,
    transaction_id: transaction_id,
  });
};

export const dynamicConversion = (action, hash, value, currency, transaction_id) => {
  window.gtag("event", action, {
    // send_to: AW_TRACKING_ID + "/2DOmCOvkwV4Qgu6ZzQM",
    send_to: AW_TRACKING_ID + '/' + hash,
    value: value,
    currency: currency,
    transaction_id: transaction_id,
  });
};

export const customConversion = (action, hash) => {
  window.gtag("event", action, {
    send_to: AW_TRACKING_ID + hash,
  });
};

export const customPageView = (id, page, value) => {
  window.gtag("event", "page_view", {
    send_to: AW_TRACKING_ID,
    ecomm_prodid: id,
    ecomm_pagetype: page,
    ecomm_totalvalue: value,
    items: [
      {
        id: id,
        google_buisness_vertical: "retail",
      },
    ],
  });
};
